import MemoLoader from "app/components/icons/Loader";
import { useStickyState } from "app/hooks/sticky-state.hook";
import { userActions } from "app/store/slices/user";
import { useAppDispatch } from "app/store/store";
import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";
import { useGetPillowV2Country } from "services-hooks/hooks";
import { getPillowCategoryPath } from "services-hooks/services";

export const LOCAL_STORAGE_COUNTRY_KEY = "countryCode-v2";
export const LOCAL_STORAGE_LOCALE_KEY = "locale";
export const LOCAL_STORAGE_LOCALE_OVERRIDE_KEY = "locale-override";

interface CountryContextValue {
  country: Country;
  setCountry: (country: Country) => void;
  localeOverride: string | null;
  setLocaleOverride: (localeOverride: string | null) => void;
}
export interface Country {
  countryCode: string;
  name: string;
  logo: string;
  permalink: string;
  ip?: string;
}

const CountryContext = createContext<CountryContextValue | null>(null);

export const CountryContextProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const { locale } = useRouter();
  const [countryValidationLoading, setCountryValidationLoading] =
    useState(false);
  const {
    data: ipBasedCountryData,
    isLoading: ipBasedLoading,
    isFetched: isIpBasedCounryFetched,
    refetch,
  } = useGetPillowV2Country({ enabled: false, retry: 1 });
  const [country, setCountry] = useStickyState<Country | null>(
    LOCAL_STORAGE_COUNTRY_KEY,
    null
  );
  const [localeOverride, setLocaleOverride] = useStickyState<string | null>(
    LOCAL_STORAGE_LOCALE_OVERRIDE_KEY,
    null
  );
  const ipBasedCountryCode =
    ipBasedCountryData?.data.data.country?.toLowerCase() || "gb";
  const ip = ipBasedCountryData?.data.data.ip || "";

  useEffect(() => {
    async function validateCountry() {
      setCountryValidationLoading(true);
      try {
        const countryResponse = await getPillowCategoryPath(
          `categories/radioline_countries/${ipBasedCountryCode}`,
          {}
        );
        const pageData = countryResponse.data.path
          ? countryResponse.data.path[countryResponse.data.path.length - 1] ||
            null
          : null;
        if (pageData && pageData.name && pageData.logo && pageData.permalink) {
          setCountry({
            countryCode: ipBasedCountryCode,
            name: pageData.name,
            logo: pageData.logo,
            permalink: pageData.permalink,
            ip,
          });
        } else {
          throw new Error("Missing data from radioline countries endpoint");
        }
      } catch (e) {
        try {
          const response = await getPillowCategoryPath(
            `categories/radioline_countries/gb`,
            {}
          );
          const pageData = response.data.path
            ? response.data.path[response.data.path.length - 1] || null
            : null;
          if (pageData) {
            if (pageData.name && pageData.logo && pageData.permalink) {
              setCountry({
                countryCode: "gb",
                name: pageData.name,
                logo: pageData.logo,
                permalink: pageData.permalink,
                ip,
              });
            }
          }
        } catch (e) {}
      }
      setCountryValidationLoading(false);
    }
    if (isIpBasedCounryFetched) {
      validateCountry();
    }
  }, [ipBasedCountryCode, isIpBasedCounryFetched]);

  useEffect(() => {
    if (window.localStorage) {
      window.localStorage.setItem(LOCAL_STORAGE_LOCALE_KEY, locale!);
    }
    if (ip) {
      dispatch(userActions.setIP(ip));
    }
  }, [ip, locale]);

  useEffect(() => {
    if (!country) {
      refetch();
    }
  }, [country]);

  const isLoading = ipBasedLoading || countryValidationLoading;
  return (
    <>
      {isLoading || !country ? (
        <div className="loader fullpage">
          <MemoLoader />
        </div>
      ) : (
        <CountryContext.Provider
          value={{ country, setCountry, setLocaleOverride, localeOverride }}
        >
          {children}
        </CountryContext.Provider>
      )}
    </>
  );
};

export const useCountryContext = (): CountryContextValue => {
  const value = useContext(CountryContext);
  if (value === null)
    throw new Error(
      "Make sure that you use your hook inside of CountryContext"
    );
  return value;
};
