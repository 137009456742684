import { selectUserState, userActions } from "app/store/slices/user";
import { useAppDispatch, useAppSelector } from "app/store/store";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { Session } from "services-hooks/types";
import {
  getPillowUserV2UsersMe,
  postPillowSessionV2SessionCreate,
  postPillowSessionV2SessionLogout,
  postPillowUserV2UsersPasswordReset,
  postPillowUserV2UsersRegister,
} from "services-hooks/services";
import { playerActions } from "app/store/slices/player";
import * as Sentry from "@sentry/nextjs";
import { db } from "app/utils/db";
import {
  refreshToken as authRefreshToken,
  signIn as authSignIn,
} from "app/services/auth.service";
import qs from "qs";

const REDIRECT_KEY = "REDIRECT_KEY";
interface AuthUserContextValue {
  signInUser: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  signUp: (email: string, password: string) => Promise<void>;
  clearRedirect: VoidFunction;
  redirectToFacebookLogin: VoidFunction;
  resetPassword: (email: string) => Promise<void>;
  refreshUserData: () => Promise<void>;
  continuityLoaded: boolean;
  setContinuityLoaded: (loaded: boolean) => void;
}

const AuthUserContext = createContext<AuthUserContextValue | null>(null);

export const AuthUserProvider: React.FC = ({ children }) => {
  const [continuityLoaded, setContinuityLoaded] = useState(false);
  const { session, deviceId } = useAppSelector(selectUserState);
  const dispatch = useAppDispatch();
  const timer = useRef<NodeJS.Timeout>();

  const clearRefreshTokenTimer = () => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
  };

  const signIn = async () => {
    try {
      const response = await authSignIn(deviceId);
      dispatch(userActions.setSession(response.data.data));
    } catch (e) {
      console.error(e);
      Sentry.captureException(e);
      signOut();
    }
  };

  const signUp = async (email: string, password: string) => {
    await postPillowUserV2UsersRegister({
      client_id: process.env.NEXT_PUBLIC_CLIENT_ID!,
      client_key: process.env.NEXT_PUBLIC_CLIENT_KEY!,
      country: "PL", // TODO: Provide current user country
      email: email,
      password: password,
    });
  };

  const signInUser = async (email: string, password: string) => {
    const response = await postPillowSessionV2SessionCreate({
      client_id: process.env.NEXT_PUBLIC_CLIENT_ID!,
      client_key: process.env.NEXT_PUBLIC_CLIENT_KEY!,
      device_serial: deviceId,
      email,
      password,
    });
    dispatch(userActions.setSession(response.data.data));
    dispatch(userActions.loadFavorites());
  };
  
  const redirectToFacebookLogin = () => {
    window.location.href =
      "https://www.facebook.com/v14.0/dialog/oauth?" +
      qs.stringify({
        client_id: process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID,
        redirect_uri: `${window.location.origin}/facebook`,
        response_type: "token",
        scope: "public_profile",
      });
  };

  const refreshUserData = async () => {
    if (session?.user) {
      try {
        const response = await getPillowUserV2UsersMe();
        if (response.data && response.data.data) {
          dispatch(
            userActions.setUser({
              country: response.data.data.country as string,
              email: response.data.data.email as string,
              first_name: response.data.data.first_name as string,
              id: response.data.data.id as string,
              last_name: response.data.data.last_name as string,
              permalink: response.data.data.permalink as string,
              premium: response.data.data.premium as boolean,
              spotify_connect: response.data.data.spotify_connect as boolean,
              deezer_connect: response.data.data.deezer_connect as boolean,
            })
          );
        }
      } catch (e) {}
    }
  };

  const resetPassword = async (email: string) => {
    await postPillowUserV2UsersPasswordReset({
      client_id: process.env.NEXT_PUBLIC_CLIENT_ID!,
      client_key: process.env.NEXT_PUBLIC_CLIENT_KEY!,
      email,
    });
  };

  const signOut = async () => {
    try {
      if (db.isOpen()) {
        await db.offlineChapters.clear();
      }
      await postPillowSessionV2SessionLogout();
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      clearRefreshTokenTimer();
    }
    setContinuityLoaded(false);
    dispatch(userActions.logout());
  };

  const clearRedirect = () => {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem(REDIRECT_KEY);
    }
  };

  useEffect(() => {
    if (!session) {
      signIn();
    }
  }, [session]);

  return (
    <AuthUserContext.Provider
      value={{
        signOut,
        clearRedirect,
        signInUser,
        signUp,
        resetPassword,
        refreshUserData,
        redirectToFacebookLogin,
        continuityLoaded,
        setContinuityLoaded,
      }}
    >
      {session ? children : null}
    </AuthUserContext.Provider>
  );
};

export const useAuth = (): AuthUserContextValue => {
  const value = useContext(AuthUserContext);
  if (value === null)
    throw new Error(
      "Make sure that you use your hook inside of AuthUserContext"
    );
  return value;
};
