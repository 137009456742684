import "../styles/globals.scss";
import type { AppProps } from "next/app";
import React, { useEffect, useMemo, useState } from "react";
import { Provider } from "react-redux";
import store, { useAppDispatch, useAppSelector } from "app/store/store";
import { Header } from "app/components/header/header.component";
import {
  QueryClient,
  QueryClientProvider,
  QueryErrorResetBoundary,
} from "react-query";
import { AuthUserProvider, useAuth } from "app/context/auth.context";
import Head from "next/head";
import { selectUserState } from "app/store/slices/user";
import {
  getAccountUsersMeContinuity,
  getPillowCategoryPath,
} from "services-hooks/services";
import { playerActions, selectPlayerState } from "app/store/slices/player";
import { Category, PodcastChapter } from "services-hooks/types";
import * as Sentry from "@sentry/nextjs";
import {
  ToastProvider,
  ToastRenderer,
  useErrorToast,
  useToast,
} from "app/components/toast/toast.component";
import { useArrowKeyNavigation } from "app/hooks/keyboard-navigation.hook";
import { Footer } from "app/components/footer/footer.component";
import "./../public/didomi.js";
import {
  CountryContextProvider,
  LOCAL_STORAGE_LOCALE_OVERRIDE_KEY,
  useCountryContext,
} from "app/context/country.context";
import {
  appVersionActions,
  selectAppVersionState,
} from "app/store/slices/app-version";
import { radioActions } from "app/store/slices/radio";
import { userActions } from "app/store/slices/user";
import { useNetworkState } from "react-use";
import {
  PlayerContextProvider,
  usePlayerContext,
} from "app/context/player.context";
import { NextIntlProvider } from "next-intl";
import { messages } from "app/locales";
import {
  FeaturesContextProvider,
  useFeaturesContext,
} from "app/context/features.context";
import { OfflineContextProvider } from "app/context/offline.context";
import { hotjar } from "react-hotjar";
const queryClient = new QueryClient();
import "core-js/modules/es.string.replace-all";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "app/components/error-fallback/error-fallback.component";
import "scroll-behavior-polyfill";
import { useVirtualPlayPauseEvent } from "app/hooks/vkplay-pause.hook";
import "intersection-observer";
import { PreviouslyPlayed } from "app/components/previously-played/previously-played.component";
import { useTranslate } from "app/hooks/lang";
import { clearMediaSession } from "app/utils/media-session";
import { NavigationContextProvider } from "app/context/navigation.context";
import Script from "next/script";
import * as gtag from "app/utils/gtag";
import { useRouter } from "next/router";
import { useStickyState } from "app/hooks/sticky-state.hook";

function MyApp({ Component, pageProps, router }: AppProps) {
  useEffect(() => {
    const dir = router.locale == "ar" ? "rtl" : "ltr";
    document.querySelector("html")?.setAttribute("dir", dir);
  }, [router.locale]);
  const [localeOverride] = useStickyState<string | null>(
    LOCAL_STORAGE_LOCALE_OVERRIDE_KEY,
    null
  );

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    router.events.on("hashChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
      router.events.off("hashChangeComplete", handleRouteChange);
    };
  }, [router.events]);
  useEffect(() => {
    if (window.navigator && navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
    if (
      process.env.NEXT_PUBLIC_HOTJAR_SV &&
      process.env.NEXT_PUBLIC_HOTJAR_ID
    ) {
      hotjar.initialize(
        parseInt(process.env.NEXT_PUBLIC_HOTJAR_ID, 10),
        parseInt(process.env.NEXT_PUBLIC_HOTJAR_SV, 10)
      );
    }
  }, []);
  if (localeOverride !== null && router.locale !== localeOverride) {
    const { pathname, asPath, query } = router;
    router.push({ pathname, query }, asPath, { locale: localeOverride });
  }
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width, viewport-fit=cover"
        />
        <link rel="apple-touch-icon" href="public/apple-icon-180.png" />
        <meta name="apple-mobile-web-app-capable" content="yes" />

        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Radioline" />
        <meta property="og:title" content="Radioline" />
        <meta
          property="og:description"
          content="Radioline - best radios and podcasts"
        />
        <meta property="og:url" content="https://app.radioline.co" />
        <meta
          property="og:image"
          content="https://app.radioline.co/radioline.jpg"
        />

        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-2048-2732.jpg"
          media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-2732-2048.jpg"
          media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-1668-2388.jpg"
          media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-2388-1668.jpg"
          media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-1536-2048.jpg"
          media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-2048-1536.jpg"
          media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-1668-2224.jpg"
          media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-2224-1668.jpg"
          media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-1620-2160.jpg"
          media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-2160-1620.jpg"
          media="(device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-1284-2778.jpg"
          media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-2778-1284.jpg"
          media="(device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-1170-2532.jpg"
          media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-2532-1170.jpg"
          media="(device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-1125-2436.jpg"
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-2436-1125.jpg"
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-1242-2688.jpg"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-2688-1242.jpg"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-828-1792.jpg"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-1792-828.jpg"
          media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-1242-2208.jpg"
          media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-2208-1242.jpg"
          media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-750-1334.jpg"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-1334-750.jpg"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-640-1136.jpg"
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)"
        />
        <link
          rel="apple-touch-startup-image"
          href="public/apple-splash-1136-640.jpg"
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)"
        />
      </Head>
      {gtag.GA_TRACKING_ID && (
        <>
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
          />
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gtag.GA_TRACKING_ID}', {
            page_path: window.location.pathname,
          });
          `,
            }}
          />
        </>
      )}
      <NextIntlProvider
        key={localeOverride ?? router.locale}
        messages={
          messages[
            (localeOverride
              ? localeOverride
              : router.locale !== router.defaultLocale
              ? router.locale
              : "en")! as keyof typeof messages
          ]
        }
      >
        <ToastProvider>
          <QueryClientProvider client={queryClient}>
            <QueryErrorResetBoundary>
              {({ reset }) => (
                <ErrorBoundary onReset={reset} fallbackRender={ErrorFallback}>
                  <Provider store={store}>
                    <OuterMyApp>
                      <AuthUserProvider>
                        <FeaturesContextProvider>
                          <OfflineContextProvider>
                            <PlayerContextProvider>
                              <CountryContextProvider>
                                <InnerMyApp>
                                  <NavigationContextProvider>
                                    <Header />
                                    <Component
                                      {...pageProps}
                                      key={router.route}
                                    />
                                  </NavigationContextProvider>
                                  <ToastRenderer />
                                </InnerMyApp>
                              </CountryContextProvider>
                            </PlayerContextProvider>
                          </OfflineContextProvider>
                        </FeaturesContextProvider>
                      </AuthUserProvider>
                    </OuterMyApp>
                  </Provider>
                </ErrorBoundary>
              )}
            </QueryErrorResetBoundary>
          </QueryClientProvider>
        </ToastProvider>
      </NextIntlProvider>
    </>
  );
}
const OuterMyApp: React.FC = ({ children }) => {
  const { version } = useAppSelector(selectAppVersionState);
  const dispatch = useAppDispatch();

  const resetAndReload = () => {
    dispatch(playerActions.reset());
    dispatch(radioActions.reset());
    dispatch(userActions.reset());

    window.location.reload();
  };

  useEffect(() => {
    if (version !== process.env.packageVersion) {
      dispatch(playerActions.reset());
      dispatch(radioActions.reset());
    }

    dispatch(
      appVersionActions.setAppVersion({
        version: process.env.packageVersion || null,
      })
    );
  }, []);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        resetAndReload();
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

const InnerMyApp: React.FC = (props) => {
  const { session } = useAppSelector(selectUserState);
  const { activeMedia } = useAppSelector(selectPlayerState);
  const { previouslyPlaying } = useAppSelector(selectUserState);
  const { setIsMediaLoading, setPlayerEnabled, setIsPlaying } =
    usePlayerContext();
  const { continuityLoaded, setContinuityLoaded } = useAuth();
  const { showFooter, arrowNavigationEnabled, forceAutoplay, hideScrollbars } =
    useFeaturesContext();
  useArrowKeyNavigation(arrowNavigationEnabled);
  const dispatch = useAppDispatch();
  const networkState = useNetworkState();
  const [slowConnectionDetected, setSlowConnectionDetected] = useState(false);
  const t = useTranslate();
  const showOfflineError = useErrorToast({
    content: t("network.connection-lost"),
  });
  const showSlowNetworkError = useToast({
    content: t("network.connection-slow"),
  });
  const showOnlineToast = useToast({
    content: t("network.back-online"),
  });
  useEffect(() => {
    if (!networkState.online) {
      showOfflineError();
    }
  }, [networkState.online]);
  useEffect(() => {
    console.log("networkState change", networkState);
    if (networkState.online && networkState.previous === false) {
      showOnlineToast();
    }
  }, [networkState.online, networkState.previous]);

  useEffect(() => {
    if (
      !slowConnectionDetected &&
      networkState.effectiveType &&
      ["2g", "slow-2g"].includes(networkState.effectiveType)
    ) {
      showSlowNetworkError();
      setSlowConnectionDetected(true);
    }
    if (
      slowConnectionDetected &&
      networkState.effectiveType &&
      ["4g", "3g"].includes(networkState.effectiveType)
    ) {
      setSlowConnectionDetected(false);
    }
  }, [slowConnectionDetected, networkState.effectiveType]);

  useEffect(() => {
    if (forceAutoplay) {
      setPlayerEnabled(true);
    }
    setIsMediaLoading(false);
  }, []);

  useEffect(() => {
    if (hideScrollbars && document && document.body) {
      document.body.classList.add("hide_scrollbars");
      document.body.parentElement?.classList.add("hide_scrollbars");
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const continuityResponse = await getAccountUsersMeContinuity();
        if (continuityResponse.data.body?.content?.listen) {
          const result = await getPillowCategoryPath(
            continuityResponse.data.body?.content?.listen,
            {}
          );
          const continuityCategory = result.data.body?.content as Category;
          if (continuityCategory) {
            if (continuityCategory?.type === "radio") {
              if (
                activeMedia?.category?.permalink !==
                  continuityCategory.permalink &&
                previouslyPlaying?.media?.podcast?.podcastChapter !==
                  continuityCategory.permalink
              ) {
                dispatch(
                  userActions.setPreviouslyPlaying({
                    dismissed: false,
                    media: {
                      podcast: null,
                      category: continuityCategory,
                    },
                  })
                );
              }
            } else if (continuityCategory.type === "chapter") {
              const isDifferentThanActive =
                activeMedia?.podcast?.podcastChapter.permalink !==
                continuityCategory.permalink;
              const isDifferentThanDismissed =
                previouslyPlaying?.media?.podcast?.podcastChapter !==
                continuityCategory.permalink;
              console.log(
                "isDifferentThanActive",
                isDifferentThanActive,
                "isDifferentThanDismissed",
                isDifferentThanDismissed
              );
              if (isDifferentThanActive && isDifferentThanDismissed) {
                const result = await getPillowCategoryPath(
                  (continuityCategory as PodcastChapter).podcastPermalink!,
                  {}
                );
                if (result.data.body?.content) {
                  dispatch(
                    userActions.setPreviouslyPlaying({
                      dismissed: false,
                      media: {
                        podcast: {
                          podcast: result.data.body?.content as Category,
                          podcastChapter: continuityCategory,
                        },
                        category: null,
                      },
                    })
                  );
                }
              }
            }
          }
        }
        setContinuityLoaded(true);
      } catch (e) {
        Sentry.captureException(e);
        console.error("Could not play last played item");
      }
    };
    if (session?.user?.id && !continuityLoaded) {
      fetchData();
    }
  }, [session?.user?.id, continuityLoaded]);

  useVirtualPlayPauseEvent(
    () => {
      setIsPlaying(true);
    },
    () => {
      setIsPlaying(false);
    },
    arrowNavigationEnabled
  );
  useEffect(() => {
    if (!activeMedia) {
      clearMediaSession();
    }
  }, [activeMedia]);
  return (
    <>
      {props.children}
      <PreviouslyPlayed />
      <Footer showFooter={showFooter} />
    </>
  );
};

export default MyApp;
