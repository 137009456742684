
/**
* AUTO_GENERATED Do not change this file directly, use config.ts file instead
*
* @version 5
*/

import { useMemo } from "react";
import { AxiosRequestConfig } from "axios";
import {
  UseQueryOptions,
  useQuery,
  useMutation,
  UseMutationOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  QueryClient,
} from "react-query";
import { RequestError, SwaggerResponse } from "./config";
import { paginationFlattenData, getPageSize, getTotal } from "./hooksConfig";

const useHasMore = (
  pages: Array<SwaggerResponse<any>> | undefined,
  list: any,
  queryParams: any,
) =>
  useMemo(() => {
    if (!pages || (pages && pages.length < 1)) {
      return false;
    }

    const total = getTotal(pages);

    if (total !== undefined) {
      if (list && list.length < total) {
        return true;
      }
      return false;
    }
    if (
      paginationFlattenData([pages[pages.length - 1]])?.length === getPageSize(queryParams as any)
    ) {
      return true;
    }

    return false;
  }, [pages, list, queryParams]);

import { AddToPlaylistResponse, AppleIDLoginRequest, Categories, CategoryResponse, ChapterProgress, ChapterProgressResponse, ChapterProgressUpdateRequest, ChapterStreams, ContactFormBody, CountryResponse, DeezerConnectRequest, DeleteAccountUsersMePresetsOfflineQueryParams, DeleteAccountUsersMePresetsQueryParams, Error, FacebookLoginRequest, GetAccountUsersMePresetsQueryParams, GetPillowCategoriesPathQueryParams, GetPillowCategoryPathQueryParams, GetPillowChapterLiveQueryParams, GetPillowMetadataHistorySearchQueryParams, GetPillowMetadataLiveSearchQueryParams, GetPillowPodcastChaptersQueryParams, GetPillowRadioHistorySearchQueryParams, GetPillowRadioLiveQueryParams, GetPillowRadioPodcastsQueryParams, GetPillowRadioSchedulesTodayQueryParams, GetPillowSearchQueryParams, GetPillowTagsQueryParams, LoginCodeAcceptRequest, LoginCodeResponse, OfflinePreset, PaymentLinkRequest, PaymentLinkResponse, PodcastChapters, PodcastChaptersProgressResponse, PostAccountUsersMePresetsOfflineQueryParams, PostAccountUsersMePresetsQueryParams, PresetsResponse, PutAccountUsersMePresetsQueryParams, RadioHistory, RadioLiveMetadata, RadioPodcasts, RadioSchedule, RequestBodyPresetArray, SearchResults, Session, SessionCreateRequest, SessionRefreshRequest, SessionResponse, SpotifyConnectRequest, Streams, TargetspoConfig, User, UserContinuity, UserCreateRequest, UserPasswordResetRequest, UserPremiumUpdateRequest, UserRefreshResponse, UserResponse, WallItems,}  from "./types"
import { deleteAccountUsersMePresets, deleteAccountUsersMePresetsOffline, deletePillowUserV2UsersMeDeezer, deletePillowUserV2UsersMeSpotify, getAccountUsersMeContinuity, getAccountUsersMePresets, getPillowAdvertisingTargetspot, getPillowCategories, getPillowCategoriesPath, getPillowCategoryPath, getPillowChapterLive, getPillowListenV2ProgressChapter_id, getPillowListenV2ProgressPodcast, getPillowMetadataHistorySearch, getPillowMetadataLiveSearch, getPillowPodcast, getPillowPodcastChapters, getPillowPodcastLatest, getPillowPodcastRadio, getPillowPodcastSimilar, getPillowPodcastchapter, getPillowPodcastchapterPlay, getPillowRadio, getPillowRadioHistory, getPillowRadioHistorySearch, getPillowRadioLive, getPillowRadioPlay, getPillowRadioPodcasts, getPillowRadioSchedulesToday, getPillowRadioSimilar, getPillowSearch, getPillowSessionV2Login_code, getPillowSessionV2SessionInfo, getPillowSuggestTypeQuery, getPillowTags, getPillowTagsTag, getPillowUserV2UsersMe, getPillowV2Country, getPillowWall, postAccountUsersMePresets, postAccountUsersMePresetsOffline, postAccountUsersMeSupport, postPillowBillingStripeV2Payment_link, postPillowDeezerV2DeezerTrack_addRadio_id, postPillowListenV2ProgressChapter_id, postPillowSessionSupport, postPillowSessionV2Login_codeAccept, postPillowSessionV2SessionCreate, postPillowSessionV2SessionExternalAppleid, postPillowSessionV2SessionExternalFacebook, postPillowSessionV2SessionLogout, postPillowSessionV2SessionRefresh, postPillowSpotifyV2SpotifyTrack_addRadio_id, postPillowUserV2UsersMeDeezer, postPillowUserV2UsersMePremium, postPillowUserV2UsersMeSpotify, postPillowUserV2UsersPasswordReset, postPillowUserV2UsersRegister, putAccountUsersMePresets,}  from "./services"

      
/**
 * delete presets from user
 */
export const useDeleteAccountUsersMePresets =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<any>, RequestError | Error,{requestBody: RequestBodyPresetArray,queryParams: DeleteAccountUsersMePresetsQueryParams, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<any>, RequestError | Error, {requestBody: RequestBodyPresetArray,queryParams: DeleteAccountUsersMePresetsQueryParams, _extraVariables?: TExtra }>((
             { 
          requestBody,
          queryParams,
          }
          )=>deleteAccountUsersMePresets(
             
          requestBody,
          queryParams,
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * set a preset not offline
 */
export const useDeleteAccountUsersMePresetsOffline =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<any>, RequestError | Error,{queryParams: DeleteAccountUsersMePresetsOfflineQueryParams, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<any>, RequestError | Error, {queryParams: DeleteAccountUsersMePresetsOfflineQueryParams, _extraVariables?: TExtra }>((
             { 
          
          queryParams,
          }
          )=>deleteAccountUsersMePresetsOffline(
             
          
          queryParams,
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Disconnect Deezer user.
 */
export const useDeletePillowUserV2UsersMeDeezer =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<UserResponse>, RequestError | Error,{_extraVariables?:TExtra} | void>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<UserResponse>, RequestError | Error, {_extraVariables?:TExtra} | void>((
             
          )=>deletePillowUserV2UsersMeDeezer(
             
          
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Disconnect Spotify user.
 */
export const useDeletePillowUserV2UsersMeSpotify =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<UserResponse>, RequestError | Error,{_extraVariables?:TExtra} | void>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<UserResponse>, RequestError | Error, {_extraVariables?:TExtra} | void>((
             
          )=>deletePillowUserV2UsersMeSpotify(
             
          
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * get continuity
 */
export const useGetAccountUsersMeContinuity = (
           options?:UseQueryOptions<SwaggerResponse<UserContinuity>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetAccountUsersMeContinuity.info( 
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<UserContinuity>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetAccountUsersMeContinuity.info = (options?:UseQueryOptions<SwaggerResponse<UserContinuity>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getAccountUsersMeContinuity.key,
            
            
            ],
                fun: () =>
                getAccountUsersMeContinuity(
                   
          
          
          
                  configOverride
                ),
              };
            };useGetAccountUsersMeContinuity.prefetch = (
            client: QueryClient,
            options?:UseQueryOptions<SwaggerResponse<UserContinuity>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetAccountUsersMeContinuity.info( 
          
          
           options,configOverride);

                return client.getQueryData([getAccountUsersMeContinuity.key,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get user presets
 */
export const useGetAccountUsersMePresets = (
           queryParams: GetAccountUsersMePresetsQueryParams,options?:UseQueryOptions<SwaggerResponse<PresetsResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetAccountUsersMePresets.info( 
          
          queryParams,
           options,configOverride);
          return useQuery<SwaggerResponse<PresetsResponse>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetAccountUsersMePresets.info = (queryParams: GetAccountUsersMePresetsQueryParams,options?:UseQueryOptions<SwaggerResponse<PresetsResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getAccountUsersMePresets.key,
            
            queryParams,
            ],
                fun: (_param?: GetAccountUsersMePresetsQueryParams,) =>
                getAccountUsersMePresets(
                   
          
          queryParams,
          
                  configOverride
                ),
              };
            };useGetAccountUsersMePresets.prefetch = (
            client: QueryClient,
            queryParams: GetAccountUsersMePresetsQueryParams,options?:UseQueryOptions<SwaggerResponse<PresetsResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetAccountUsersMePresets.info( 
          
          queryParams,
           options,configOverride);

                return client.getQueryData([getAccountUsersMePresets.key,
            
            queryParams,
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get Targetspot config
 */
export const useGetPillowAdvertisingTargetspot = (
           options?:UseQueryOptions<SwaggerResponse<TargetspoConfig>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowAdvertisingTargetspot.info( 
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<TargetspoConfig>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowAdvertisingTargetspot.info = (options?:UseQueryOptions<SwaggerResponse<TargetspoConfig>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowAdvertisingTargetspot.key,
            
            
            ],
                fun: () =>
                getPillowAdvertisingTargetspot(
                   
          
          
          
                  configOverride
                ),
              };
            };useGetPillowAdvertisingTargetspot.prefetch = (
            client: QueryClient,
            options?:UseQueryOptions<SwaggerResponse<TargetspoConfig>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowAdvertisingTargetspot.info( 
          
          
           options,configOverride);

                return client.getQueryData([getPillowAdvertisingTargetspot.key,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get the root categories
 */
export const useGetPillowCategories = (
           options?:UseQueryOptions<SwaggerResponse<Categories>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowCategories.info( 
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<Categories>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowCategories.info = (options?:UseQueryOptions<SwaggerResponse<Categories>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowCategories.key,
            
            
            ],
                fun: () =>
                getPillowCategories(
                   
          
          
          
                  configOverride
                ),
              };
            };useGetPillowCategories.prefetch = (
            client: QueryClient,
            options?:UseQueryOptions<SwaggerResponse<Categories>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowCategories.info( 
          
          
           options,configOverride);

                return client.getQueryData([getPillowCategories.key,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get a path
 */
export const useGetPillowCategoriesPath = (
           path: string,queryParams: GetPillowCategoriesPathQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<Categories>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowCategoriesPath.info( path,
          
          queryParams,
           options,configOverride);
          const {
            data: { pages } = {},
            data,
            ...rest
          } = useInfiniteQuery(
            key,
            ({ pageParam = 1 }) =>
              fun({
                  page:pageParam,
              }),
            {
              getNextPageParam: (_lastPage, allPages) => allPages.length + 1,
              ...(options as any),
            },
          );
        
          const list = useMemo(() => paginationFlattenData(pages), [pages]);

          const hasMore = useHasMore(pages, list, queryParams);
          
          return {...rest, data, list, hasMore}
            
          }
        useGetPillowCategoriesPath.info = (path: string,queryParams: GetPillowCategoriesPathQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<Categories>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowCategoriesPath.key,path,
            
            queryParams,
            ],
                fun: (_param?: GetPillowCategoriesPathQueryParams,) =>
                getPillowCategoriesPath(
                   path,
          
          {
                  ..._param,
                  ...queryParams,
                },
          
                  configOverride
                ),
              };
            };useGetPillowCategoriesPath.prefetch = (
            client: QueryClient,
            path: string,queryParams: GetPillowCategoriesPathQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<Categories>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowCategoriesPath.info( path,
          
          queryParams,
           options,configOverride);

                return client.getQueryData([getPillowCategoriesPath.key,path,
            
            queryParams,
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get a path
 */
export const useGetPillowCategoryPath = (
           categoryPath: string,queryParams: GetPillowCategoryPathQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<Categories>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowCategoryPath.info( categoryPath,
          
          queryParams,
           options,configOverride);
          const {
            data: { pages } = {},
            data,
            ...rest
          } = useInfiniteQuery(
            key,
            ({ pageParam = 1 }) =>
              fun({
                  page:pageParam,
              }),
            {
              getNextPageParam: (_lastPage, allPages) => allPages.length + 1,
              ...(options as any),
            },
          );
        
          const list = useMemo(() => paginationFlattenData(pages), [pages]);

          const hasMore = useHasMore(pages, list, queryParams);
          
          return {...rest, data, list, hasMore}
            
          }
        useGetPillowCategoryPath.info = (categoryPath: string,queryParams: GetPillowCategoryPathQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<Categories>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowCategoryPath.key,categoryPath,
            
            queryParams,
            ],
                fun: (_param?: GetPillowCategoryPathQueryParams,) =>
                getPillowCategoryPath(
                   categoryPath,
          
          {
                  ..._param,
                  ...queryParams,
                },
          
                  configOverride
                ),
              };
            };useGetPillowCategoryPath.prefetch = (
            client: QueryClient,
            categoryPath: string,queryParams: GetPillowCategoryPathQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<Categories>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowCategoryPath.info( categoryPath,
          
          queryParams,
           options,configOverride);

                return client.getQueryData([getPillowCategoryPath.key,categoryPath,
            
            queryParams,
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get a chapter track
 */
export const useGetPillowChapterLive = (
           chapter: string,queryParams: GetPillowChapterLiveQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowChapterLive.info( chapter,
          
          queryParams,
           options,configOverride);
          return useQuery<SwaggerResponse<any>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowChapterLive.info = (chapter: string,queryParams: GetPillowChapterLiveQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowChapterLive.key,chapter,
            
            queryParams,
            ],
                fun: (_param?: GetPillowChapterLiveQueryParams,) =>
                getPillowChapterLive(
                   chapter,
          
          queryParams,
          
                  configOverride
                ),
              };
            };useGetPillowChapterLive.prefetch = (
            client: QueryClient,
            chapter: string,queryParams: GetPillowChapterLiveQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowChapterLive.info( chapter,
          
          queryParams,
           options,configOverride);

                return client.getQueryData([getPillowChapterLive.key,chapter,
            
            queryParams,
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * Read chapter progress.
 */
export const useGetPillowListenV2ProgressChapter_id = (
           chapter_id: string,options?:UseQueryOptions<SwaggerResponse<ChapterProgressResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowListenV2ProgressChapter_id.info( chapter_id,
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<ChapterProgressResponse>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowListenV2ProgressChapter_id.info = (chapter_id: string,options?:UseQueryOptions<SwaggerResponse<ChapterProgressResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowListenV2ProgressChapter_id.key,chapter_id,
            
            
            ],
                fun: () =>
                getPillowListenV2ProgressChapter_id(
                   chapter_id,
          
          
          
                  configOverride
                ),
              };
            };useGetPillowListenV2ProgressChapter_id.prefetch = (
            client: QueryClient,
            chapter_id: string,options?:UseQueryOptions<SwaggerResponse<ChapterProgressResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowListenV2ProgressChapter_id.info( chapter_id,
          
          
           options,configOverride);

                return client.getQueryData([getPillowListenV2ProgressChapter_id.key,chapter_id,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * Read podcast chapters progress.
 */
export const useGetPillowListenV2ProgressPodcast = (
           podcast: string,options?:UseQueryOptions<SwaggerResponse<PodcastChaptersProgressResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowListenV2ProgressPodcast.info( podcast,
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<PodcastChaptersProgressResponse>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowListenV2ProgressPodcast.info = (podcast: string,options?:UseQueryOptions<SwaggerResponse<PodcastChaptersProgressResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowListenV2ProgressPodcast.key,podcast,
            
            
            ],
                fun: () =>
                getPillowListenV2ProgressPodcast(
                   podcast,
          
          
          
                  configOverride
                ),
              };
            };useGetPillowListenV2ProgressPodcast.prefetch = (
            client: QueryClient,
            podcast: string,options?:UseQueryOptions<SwaggerResponse<PodcastChaptersProgressResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowListenV2ProgressPodcast.info( podcast,
          
          
           options,configOverride);

                return client.getQueryData([getPillowListenV2ProgressPodcast.key,podcast,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * find metadata in radio history
 */
export const useGetPillowMetadataHistorySearch = (
           queryParams: GetPillowMetadataHistorySearchQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowMetadataHistorySearch.info( 
          
          queryParams,
           options,configOverride);
          return useQuery<SwaggerResponse<any>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowMetadataHistorySearch.info = (queryParams: GetPillowMetadataHistorySearchQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowMetadataHistorySearch.key,
            
            queryParams,
            ],
                fun: (_param?: GetPillowMetadataHistorySearchQueryParams,) =>
                getPillowMetadataHistorySearch(
                   
          
          queryParams,
          
                  configOverride
                ),
              };
            };useGetPillowMetadataHistorySearch.prefetch = (
            client: QueryClient,
            queryParams: GetPillowMetadataHistorySearchQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowMetadataHistorySearch.info( 
          
          queryParams,
           options,configOverride);

                return client.getQueryData([getPillowMetadataHistorySearch.key,
            
            queryParams,
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * find metadata live on radios
 */
export const useGetPillowMetadataLiveSearch = (
           queryParams: GetPillowMetadataLiveSearchQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowMetadataLiveSearch.info( 
          
          queryParams,
           options,configOverride);
          return useQuery<SwaggerResponse<any>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowMetadataLiveSearch.info = (queryParams: GetPillowMetadataLiveSearchQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowMetadataLiveSearch.key,
            
            queryParams,
            ],
                fun: (_param?: GetPillowMetadataLiveSearchQueryParams,) =>
                getPillowMetadataLiveSearch(
                   
          
          queryParams,
          
                  configOverride
                ),
              };
            };useGetPillowMetadataLiveSearch.prefetch = (
            client: QueryClient,
            queryParams: GetPillowMetadataLiveSearchQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowMetadataLiveSearch.info( 
          
          queryParams,
           options,configOverride);

                return client.getQueryData([getPillowMetadataLiveSearch.key,
            
            queryParams,
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get a podcast
 */
export const useGetPillowPodcast = (
           podcast: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowPodcast.info( podcast,
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<any>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowPodcast.info = (podcast: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowPodcast.key,podcast,
            
            
            ],
                fun: () =>
                getPillowPodcast(
                   podcast,
          
          
          
                  configOverride
                ),
              };
            };useGetPillowPodcast.prefetch = (
            client: QueryClient,
            podcast: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowPodcast.info( podcast,
          
          
           options,configOverride);

                return client.getQueryData([getPillowPodcast.key,podcast,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get a podcast chapters
 */
export const useGetPillowPodcastChapters = (
           podcast: string,queryParams: GetPillowPodcastChaptersQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<PodcastChapters>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowPodcastChapters.info( podcast,
          
          queryParams,
           options,configOverride);
          const {
            data: { pages } = {},
            data,
            ...rest
          } = useInfiniteQuery(
            key,
            ({ pageParam = 1 }) =>
              fun({
                  page:pageParam,
              }),
            {
              getNextPageParam: (_lastPage, allPages) => allPages.length + 1,
              ...(options as any),
            },
          );
        
          const list = useMemo(() => paginationFlattenData(pages), [pages]);

          const hasMore = useHasMore(pages, list, queryParams);
          
          return {...rest, data, list, hasMore}
            
          }
        useGetPillowPodcastChapters.info = (podcast: string,queryParams: GetPillowPodcastChaptersQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<PodcastChapters>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowPodcastChapters.key,podcast,
            
            queryParams,
            ],
                fun: (_param?: GetPillowPodcastChaptersQueryParams,) =>
                getPillowPodcastChapters(
                   podcast,
          
          {
                  ..._param,
                  ...queryParams,
                },
          
                  configOverride
                ),
              };
            };useGetPillowPodcastChapters.prefetch = (
            client: QueryClient,
            podcast: string,queryParams: GetPillowPodcastChaptersQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<PodcastChapters>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowPodcastChapters.info( podcast,
          
          queryParams,
           options,configOverride);

                return client.getQueryData([getPillowPodcastChapters.key,podcast,
            
            queryParams,
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get a podcast radio
 */
export const useGetPillowPodcastLatest = (
           podcast: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowPodcastLatest.info( podcast,
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<any>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowPodcastLatest.info = (podcast: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowPodcastLatest.key,podcast,
            
            
            ],
                fun: () =>
                getPillowPodcastLatest(
                   podcast,
          
          
          
                  configOverride
                ),
              };
            };useGetPillowPodcastLatest.prefetch = (
            client: QueryClient,
            podcast: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowPodcastLatest.info( podcast,
          
          
           options,configOverride);

                return client.getQueryData([getPillowPodcastLatest.key,podcast,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get a podcast radio
 */
export const useGetPillowPodcastRadio = (
           podcast: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowPodcastRadio.info( podcast,
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<any>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowPodcastRadio.info = (podcast: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowPodcastRadio.key,podcast,
            
            
            ],
                fun: () =>
                getPillowPodcastRadio(
                   podcast,
          
          
          
                  configOverride
                ),
              };
            };useGetPillowPodcastRadio.prefetch = (
            client: QueryClient,
            podcast: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowPodcastRadio.info( podcast,
          
          
           options,configOverride);

                return client.getQueryData([getPillowPodcastRadio.key,podcast,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get a podcast radio
 */
export const useGetPillowPodcastSimilar = (
           podcast: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowPodcastSimilar.info( podcast,
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<any>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowPodcastSimilar.info = (podcast: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowPodcastSimilar.key,podcast,
            
            
            ],
                fun: () =>
                getPillowPodcastSimilar(
                   podcast,
          
          
          
                  configOverride
                ),
              };
            };useGetPillowPodcastSimilar.prefetch = (
            client: QueryClient,
            podcast: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowPodcastSimilar.info( podcast,
          
          
           options,configOverride);

                return client.getQueryData([getPillowPodcastSimilar.key,podcast,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get a chapter
 */
export const useGetPillowPodcastchapter = (
           podcastchapter: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowPodcastchapter.info( podcastchapter,
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<any>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowPodcastchapter.info = (podcastchapter: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowPodcastchapter.key,podcastchapter,
            
            
            ],
                fun: () =>
                getPillowPodcastchapter(
                   podcastchapter,
          
          
          
                  configOverride
                ),
              };
            };useGetPillowPodcastchapter.prefetch = (
            client: QueryClient,
            podcastchapter: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowPodcastchapter.info( podcastchapter,
          
          
           options,configOverride);

                return client.getQueryData([getPillowPodcastchapter.key,podcastchapter,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get a chapter streams
 */
export const useGetPillowPodcastchapterPlay = (
           podcastchapter: string,options?:UseQueryOptions<SwaggerResponse<ChapterStreams>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowPodcastchapterPlay.info( podcastchapter,
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<ChapterStreams>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowPodcastchapterPlay.info = (podcastchapter: string,options?:UseQueryOptions<SwaggerResponse<ChapterStreams>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowPodcastchapterPlay.key,podcastchapter,
            
            
            ],
                fun: () =>
                getPillowPodcastchapterPlay(
                   podcastchapter,
          
          
          
                  configOverride
                ),
              };
            };useGetPillowPodcastchapterPlay.prefetch = (
            client: QueryClient,
            podcastchapter: string,options?:UseQueryOptions<SwaggerResponse<ChapterStreams>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowPodcastchapterPlay.info( podcastchapter,
          
          
           options,configOverride);

                return client.getQueryData([getPillowPodcastchapterPlay.key,podcastchapter,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get a radio
 */
export const useGetPillowRadio = (
           radio: string,options?:UseQueryOptions<SwaggerResponse<CategoryResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowRadio.info( radio,
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<CategoryResponse>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowRadio.info = (radio: string,options?:UseQueryOptions<SwaggerResponse<CategoryResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowRadio.key,radio,
            
            
            ],
                fun: () =>
                getPillowRadio(
                   radio,
          
          
          
                  configOverride
                ),
              };
            };useGetPillowRadio.prefetch = (
            client: QueryClient,
            radio: string,options?:UseQueryOptions<SwaggerResponse<CategoryResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowRadio.info( radio,
          
          
           options,configOverride);

                return client.getQueryData([getPillowRadio.key,radio,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * Get the last 10 tracks from a given radio
 */
export const useGetPillowRadioHistory = (
           radio: string,options?:UseQueryOptions<SwaggerResponse<RadioHistory>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowRadioHistory.info( radio,
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<RadioHistory>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowRadioHistory.info = (radio: string,options?:UseQueryOptions<SwaggerResponse<RadioHistory>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowRadioHistory.key,radio,
            
            
            ],
                fun: () =>
                getPillowRadioHistory(
                   radio,
          
          
          
                  configOverride
                ),
              };
            };useGetPillowRadioHistory.prefetch = (
            client: QueryClient,
            radio: string,options?:UseQueryOptions<SwaggerResponse<RadioHistory>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowRadioHistory.info( radio,
          
          
           options,configOverride);

                return client.getQueryData([getPillowRadioHistory.key,radio,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * find metadata in radio history
 */
export const useGetPillowRadioHistorySearch = (
           radio: string,queryParams: GetPillowRadioHistorySearchQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowRadioHistorySearch.info( radio,
          
          queryParams,
           options,configOverride);
          return useQuery<SwaggerResponse<any>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowRadioHistorySearch.info = (radio: string,queryParams: GetPillowRadioHistorySearchQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowRadioHistorySearch.key,radio,
            
            queryParams,
            ],
                fun: (_param?: GetPillowRadioHistorySearchQueryParams,) =>
                getPillowRadioHistorySearch(
                   radio,
          
          queryParams,
          
                  configOverride
                ),
              };
            };useGetPillowRadioHistorySearch.prefetch = (
            client: QueryClient,
            radio: string,queryParams: GetPillowRadioHistorySearchQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowRadioHistorySearch.info( radio,
          
          queryParams,
           options,configOverride);

                return client.getQueryData([getPillowRadioHistorySearch.key,radio,
            
            queryParams,
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * the current state hash of the radio
 */
export const useGetPillowRadioLive = (
           radio: string,queryParams: GetPillowRadioLiveQueryParams,options?:UseQueryOptions<SwaggerResponse<RadioLiveMetadata>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowRadioLive.info( radio,
          
          queryParams,
           options,configOverride);
          return useQuery<SwaggerResponse<RadioLiveMetadata>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowRadioLive.info = (radio: string,queryParams: GetPillowRadioLiveQueryParams,options?:UseQueryOptions<SwaggerResponse<RadioLiveMetadata>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowRadioLive.key,radio,
            
            queryParams,
            ],
                fun: (_param?: GetPillowRadioLiveQueryParams,) =>
                getPillowRadioLive(
                   radio,
          
          queryParams,
          
                  configOverride
                ),
              };
            };useGetPillowRadioLive.prefetch = (
            client: QueryClient,
            radio: string,queryParams: GetPillowRadioLiveQueryParams,options?:UseQueryOptions<SwaggerResponse<RadioLiveMetadata>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowRadioLive.info( radio,
          
          queryParams,
           options,configOverride);

                return client.getQueryData([getPillowRadioLive.key,radio,
            
            queryParams,
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get the streams of a radio
 */
export const useGetPillowRadioPlay = (
           radio: string,options?:UseQueryOptions<SwaggerResponse<Streams>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowRadioPlay.info( radio,
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<Streams>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowRadioPlay.info = (radio: string,options?:UseQueryOptions<SwaggerResponse<Streams>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowRadioPlay.key,radio,
            
            
            ],
                fun: () =>
                getPillowRadioPlay(
                   radio,
          
          
          
                  configOverride
                ),
              };
            };useGetPillowRadioPlay.prefetch = (
            client: QueryClient,
            radio: string,options?:UseQueryOptions<SwaggerResponse<Streams>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowRadioPlay.info( radio,
          
          
           options,configOverride);

                return client.getQueryData([getPillowRadioPlay.key,radio,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get the podcasts of a radio
 */
export const useGetPillowRadioPodcasts = (
           radio: string,queryParams: GetPillowRadioPodcastsQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<RadioPodcasts>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowRadioPodcasts.info( radio,
          
          queryParams,
           options,configOverride);
          const {
            data: { pages } = {},
            data,
            ...rest
          } = useInfiniteQuery(
            key,
            ({ pageParam = 1 }) =>
              fun({
                  page:pageParam,
              }),
            {
              getNextPageParam: (_lastPage, allPages) => allPages.length + 1,
              ...(options as any),
            },
          );
        
          const list = useMemo(() => paginationFlattenData(pages), [pages]);

          const hasMore = useHasMore(pages, list, queryParams);
          
          return {...rest, data, list, hasMore}
            
          }
        useGetPillowRadioPodcasts.info = (radio: string,queryParams: GetPillowRadioPodcastsQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<RadioPodcasts>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowRadioPodcasts.key,radio,
            
            queryParams,
            ],
                fun: (_param?: GetPillowRadioPodcastsQueryParams,) =>
                getPillowRadioPodcasts(
                   radio,
          
          {
                  ..._param,
                  ...queryParams,
                },
          
                  configOverride
                ),
              };
            };useGetPillowRadioPodcasts.prefetch = (
            client: QueryClient,
            radio: string,queryParams: GetPillowRadioPodcastsQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<RadioPodcasts>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowRadioPodcasts.info( radio,
          
          queryParams,
           options,configOverride);

                return client.getQueryData([getPillowRadioPodcasts.key,radio,
            
            queryParams,
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get radio's schedule for today
 */
export const useGetPillowRadioSchedulesToday = (
           radio: string,queryParams: GetPillowRadioSchedulesTodayQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<RadioSchedule>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowRadioSchedulesToday.info( radio,
          
          queryParams,
           options,configOverride);
          const {
            data: { pages } = {},
            data,
            ...rest
          } = useInfiniteQuery(
            key,
            ({ pageParam = 1 }) =>
              fun({
                  page:pageParam,
              }),
            {
              getNextPageParam: (_lastPage, allPages) => allPages.length + 1,
              ...(options as any),
            },
          );
        
          const list = useMemo(() => paginationFlattenData(pages), [pages]);

          const hasMore = useHasMore(pages, list, queryParams);
          
          return {...rest, data, list, hasMore}
            
          }
        useGetPillowRadioSchedulesToday.info = (radio: string,queryParams: GetPillowRadioSchedulesTodayQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<RadioSchedule>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowRadioSchedulesToday.key,radio,
            
            queryParams,
            ],
                fun: (_param?: GetPillowRadioSchedulesTodayQueryParams,) =>
                getPillowRadioSchedulesToday(
                   radio,
          
          {
                  ..._param,
                  ...queryParams,
                },
          
                  configOverride
                ),
              };
            };useGetPillowRadioSchedulesToday.prefetch = (
            client: QueryClient,
            radio: string,queryParams: GetPillowRadioSchedulesTodayQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<RadioSchedule>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowRadioSchedulesToday.info( radio,
          
          queryParams,
           options,configOverride);

                return client.getQueryData([getPillowRadioSchedulesToday.key,radio,
            
            queryParams,
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get a podcast radio
 */
export const useGetPillowRadioSimilar = (
           radio: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowRadioSimilar.info( radio,
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<any>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowRadioSimilar.info = (radio: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowRadioSimilar.key,radio,
            
            
            ],
                fun: () =>
                getPillowRadioSimilar(
                   radio,
          
          
          
                  configOverride
                ),
              };
            };useGetPillowRadioSimilar.prefetch = (
            client: QueryClient,
            radio: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowRadioSimilar.info( radio,
          
          
           options,configOverride);

                return client.getQueryData([getPillowRadioSimilar.key,radio,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * Search or filter radios and podcasts
 */
export const useGetPillowSearch = (
           queryParams: GetPillowSearchQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<SearchResults>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowSearch.info( 
          
          queryParams,
           options,configOverride);
          const {
            data: { pages } = {},
            data,
            ...rest
          } = useInfiniteQuery(
            key,
            ({ pageParam = 1 }) =>
              fun({
                  page:pageParam,
              }),
            {
              getNextPageParam: (_lastPage, allPages) => allPages.length + 1,
              ...(options as any),
            },
          );
        
          const list = useMemo(() => paginationFlattenData(pages), [pages]);

          const hasMore = useHasMore(pages, list, queryParams);
          
          return {...rest, data, list, hasMore}
            
          }
        useGetPillowSearch.info = (queryParams: GetPillowSearchQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<SearchResults>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowSearch.key,
            
            queryParams,
            ],
                fun: (_param?: GetPillowSearchQueryParams,) =>
                getPillowSearch(
                   
          
          {
                  ..._param,
                  ...queryParams,
                },
          
                  configOverride
                ),
              };
            };useGetPillowSearch.prefetch = (
            client: QueryClient,
            queryParams: GetPillowSearchQueryParams,options?:UseInfiniteQueryOptions<SwaggerResponse<SearchResults>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowSearch.info( 
          
          queryParams,
           options,configOverride);

                return client.getQueryData([getPillowSearch.key,
            
            queryParams,
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * Get login code for display in device and check it's status.
 */
export const useGetPillowSessionV2Login_code = (
           options?:UseQueryOptions<SwaggerResponse<LoginCodeResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowSessionV2Login_code.info( 
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<LoginCodeResponse>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowSessionV2Login_code.info = (options?:UseQueryOptions<SwaggerResponse<LoginCodeResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowSessionV2Login_code.key,
            
            
            ],
                fun: () =>
                getPillowSessionV2Login_code(
                   
          
          
          
                  configOverride
                ),
              };
            };useGetPillowSessionV2Login_code.prefetch = (
            client: QueryClient,
            options?:UseQueryOptions<SwaggerResponse<LoginCodeResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowSessionV2Login_code.info( 
          
          
           options,configOverride);

                return client.getQueryData([getPillowSessionV2Login_code.key,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * Session info.
 */
export const useGetPillowSessionV2SessionInfo = (
           options?:UseQueryOptions<SwaggerResponse<{"data": Session;}>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowSessionV2SessionInfo.info( 
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<{"data": Session;}>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowSessionV2SessionInfo.info = (options?:UseQueryOptions<SwaggerResponse<{"data": Session;}>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowSessionV2SessionInfo.key,
            
            
            ],
                fun: () =>
                getPillowSessionV2SessionInfo(
                   
          
          
          
                  configOverride
                ),
              };
            };useGetPillowSessionV2SessionInfo.prefetch = (
            client: QueryClient,
            options?:UseQueryOptions<SwaggerResponse<{"data": Session;}>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowSessionV2SessionInfo.info( 
          
          
           options,configOverride);

                return client.getQueryData([getPillowSessionV2SessionInfo.key,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      export const useGetPillowSuggestTypeQuery = (
           type: "all" | "radio" | "podcast",query: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowSuggestTypeQuery.info( type,query,
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<any>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowSuggestTypeQuery.info = (type: "all" | "radio" | "podcast",query: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowSuggestTypeQuery.key,type,query,
            
            
            ],
                fun: () =>
                getPillowSuggestTypeQuery(
                   type,query,
          
          
          
                  configOverride
                ),
              };
            };useGetPillowSuggestTypeQuery.prefetch = (
            client: QueryClient,
            type: "all" | "radio" | "podcast",query: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowSuggestTypeQuery.info( type,query,
          
          
           options,configOverride);

                return client.getQueryData([getPillowSuggestTypeQuery.key,type,query,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get all tag of a type
 */
export const useGetPillowTags = (
           queryParams: GetPillowTagsQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowTags.info( 
          
          queryParams,
           options,configOverride);
          return useQuery<SwaggerResponse<any>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowTags.info = (queryParams: GetPillowTagsQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowTags.key,
            
            queryParams,
            ],
                fun: (_param?: GetPillowTagsQueryParams,) =>
                getPillowTags(
                   
          
          queryParams,
          
                  configOverride
                ),
              };
            };useGetPillowTags.prefetch = (
            client: QueryClient,
            queryParams: GetPillowTagsQueryParams,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowTags.info( 
          
          queryParams,
           options,configOverride);

                return client.getQueryData([getPillowTags.key,
            
            queryParams,
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get a tag
 */
export const useGetPillowTagsTag = (
           tag: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowTagsTag.info( tag,
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<any>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowTagsTag.info = (tag: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowTagsTag.key,tag,
            
            
            ],
                fun: () =>
                getPillowTagsTag(
                   tag,
          
          
          
                  configOverride
                ),
              };
            };useGetPillowTagsTag.prefetch = (
            client: QueryClient,
            tag: string,options?:UseQueryOptions<SwaggerResponse<any>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowTagsTag.info( tag,
          
          
           options,configOverride);

                return client.getQueryData([getPillowTagsTag.key,tag,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * Read user info.
 */
export const useGetPillowUserV2UsersMe = (
           options?:UseQueryOptions<SwaggerResponse<UserRefreshResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowUserV2UsersMe.info( 
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<UserRefreshResponse>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowUserV2UsersMe.info = (options?:UseQueryOptions<SwaggerResponse<UserRefreshResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowUserV2UsersMe.key,
            
            
            ],
                fun: () =>
                getPillowUserV2UsersMe(
                   
          
          
          
                  configOverride
                ),
              };
            };useGetPillowUserV2UsersMe.prefetch = (
            client: QueryClient,
            options?:UseQueryOptions<SwaggerResponse<UserRefreshResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowUserV2UsersMe.info( 
          
          
           options,configOverride);

                return client.getQueryData([getPillowUserV2UsersMe.key,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get your IP's country code, WARNING it can be empty string
 */
export const useGetPillowV2Country = (
           options?:UseQueryOptions<SwaggerResponse<CountryResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowV2Country.info( 
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<CountryResponse>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowV2Country.info = (options?:UseQueryOptions<SwaggerResponse<CountryResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowV2Country.key,
            
            
            ],
                fun: () =>
                getPillowV2Country(
                   
          
          
          
                  configOverride
                ),
              };
            };useGetPillowV2Country.prefetch = (
            client: QueryClient,
            options?:UseQueryOptions<SwaggerResponse<CountryResponse>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowV2Country.info( 
          
          
           options,configOverride);

                return client.getQueryData([getPillowV2Country.key,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * get live wall
 */
export const useGetPillowWall = (
           options?:UseQueryOptions<SwaggerResponse<WallItems>, RequestError | Error>,configOverride?:AxiosRequestConfig
           ) => {
          const { key, fun } = useGetPillowWall.info( 
          
          
           options,configOverride);
          return useQuery<SwaggerResponse<WallItems>, RequestError | Error>(key,()=>
                fun(),
                options
               )  
          }
        useGetPillowWall.info = (options?:UseQueryOptions<SwaggerResponse<WallItems>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
              return {
                key: [getPillowWall.key,
            
            
            ],
                fun: () =>
                getPillowWall(
                   
          
          
          
                  configOverride
                ),
              };
            };useGetPillowWall.prefetch = (
            client: QueryClient,
            options?:UseQueryOptions<SwaggerResponse<WallItems>, RequestError | Error>,configOverride?:AxiosRequestConfig) => {
                const { key, fun } = useGetPillowWall.info( 
          
          
           options,configOverride);

                return client.getQueryData([getPillowWall.key,
            
            
            ])
                ? Promise.resolve()
                : client.prefetchQuery(
                    key,
                    ()=>fun(),
                    options
                  );
              }
      
/**
 * add presets to user
 */
export const usePostAccountUsersMePresets =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<any>, RequestError | Error,{requestBody: RequestBodyPresetArray,queryParams: PostAccountUsersMePresetsQueryParams, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<any>, RequestError | Error, {requestBody: RequestBodyPresetArray,queryParams: PostAccountUsersMePresetsQueryParams, _extraVariables?: TExtra }>((
             { 
          requestBody,
          queryParams,
          }
          )=>postAccountUsersMePresets(
             
          requestBody,
          queryParams,
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * set a preset offline property
 */
export const usePostAccountUsersMePresetsOffline =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<any>, RequestError | Error,{requestBody: OfflinePreset,queryParams: PostAccountUsersMePresetsOfflineQueryParams, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<any>, RequestError | Error, {requestBody: OfflinePreset,queryParams: PostAccountUsersMePresetsOfflineQueryParams, _extraVariables?: TExtra }>((
             { 
          requestBody,
          queryParams,
          }
          )=>postAccountUsersMePresetsOffline(
             
          requestBody,
          queryParams,
          
            configOverride,
          ),
          options
         )  
          }
        
      export const usePostAccountUsersMeSupport =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<any>, RequestError | Error,{requestBody: ContactFormBody, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<any>, RequestError | Error, {requestBody: ContactFormBody, _extraVariables?: TExtra }>((
             { 
          requestBody,
          
          }
          )=>postAccountUsersMeSupport(
             
          requestBody,
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Create payment link for logged in user.
 */
export const usePostPillowBillingStripeV2Payment_link =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<PaymentLinkResponse>, RequestError | Error,{requestBody: PaymentLinkRequest, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<PaymentLinkResponse>, RequestError | Error, {requestBody: PaymentLinkRequest, _extraVariables?: TExtra }>((
             { 
          requestBody,
          
          }
          )=>postPillowBillingStripeV2Payment_link(
             
          requestBody,
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Add to Deezer playlist track currently played in radio.
 */
export const usePostPillowDeezerV2DeezerTrack_addRadio_id =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<AddToPlaylistResponse>, RequestError | Error,{radio_id: string, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<AddToPlaylistResponse>, RequestError | Error, {radio_id: string, _extraVariables?: TExtra }>((
             { radio_id,
          
          
          }
          )=>postPillowDeezerV2DeezerTrack_addRadio_id(
             radio_id,
          
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Update chapter progress.
 */
export const usePostPillowListenV2ProgressChapter_id =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<ChapterProgress>, RequestError | Error,{chapter_id: string,requestBody: ChapterProgressUpdateRequest, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<ChapterProgress>, RequestError | Error, {chapter_id: string,requestBody: ChapterProgressUpdateRequest, _extraVariables?: TExtra }>((
             { chapter_id,
          requestBody,
          
          }
          )=>postPillowListenV2ProgressChapter_id(
             chapter_id,
          requestBody,
          
          
            configOverride,
          ),
          options
         )  
          }
        
      export const usePostPillowSessionSupport =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<any>, RequestError | Error,{requestBody: ContactFormBody, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<any>, RequestError | Error, {requestBody: ContactFormBody, _extraVariables?: TExtra }>((
             { 
          requestBody,
          
          }
          )=>postPillowSessionSupport(
             
          requestBody,
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Accept login code.
 */
export const usePostPillowSessionV2Login_codeAccept =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<any>, RequestError | Error,{requestBody: LoginCodeAcceptRequest, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<any>, RequestError | Error, {requestBody: LoginCodeAcceptRequest, _extraVariables?: TExtra }>((
             { 
          requestBody,
          
          }
          )=>postPillowSessionV2Login_codeAccept(
             
          requestBody,
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Create session and obtain access token.
 */
export const usePostPillowSessionV2SessionCreate =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<{"data": Session;}>, RequestError | Error,{requestBody: SessionCreateRequest, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<{"data": Session;}>, RequestError | Error, {requestBody: SessionCreateRequest, _extraVariables?: TExtra }>((
             { 
          requestBody,
          
          }
          )=>postPillowSessionV2SessionCreate(
             
          requestBody,
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Login with Apple ID.
 */
export const usePostPillowSessionV2SessionExternalAppleid =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<SessionResponse>, RequestError | Error,{requestBody: AppleIDLoginRequest, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<SessionResponse>, RequestError | Error, {requestBody: AppleIDLoginRequest, _extraVariables?: TExtra }>((
             { 
          requestBody,
          
          }
          )=>postPillowSessionV2SessionExternalAppleid(
             
          requestBody,
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Login with Facebook.
 */
export const usePostPillowSessionV2SessionExternalFacebook =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<SessionResponse>, RequestError | Error,{requestBody: FacebookLoginRequest, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<SessionResponse>, RequestError | Error, {requestBody: FacebookLoginRequest, _extraVariables?: TExtra }>((
             { 
          requestBody,
          
          }
          )=>postPillowSessionV2SessionExternalFacebook(
             
          requestBody,
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Logout session.
 */
export const usePostPillowSessionV2SessionLogout =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<{[x: string]: any}>, RequestError | Error,{_extraVariables?:TExtra} | void>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<{[x: string]: any}>, RequestError | Error, {_extraVariables?:TExtra} | void>((
             
          )=>postPillowSessionV2SessionLogout(
             
          
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Session refresh.
 */
export const usePostPillowSessionV2SessionRefresh =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<{"data": Session;}>, RequestError | Error,{requestBody: SessionRefreshRequest, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<{"data": Session;}>, RequestError | Error, {requestBody: SessionRefreshRequest, _extraVariables?: TExtra }>((
             { 
          requestBody,
          
          }
          )=>postPillowSessionV2SessionRefresh(
             
          requestBody,
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Add to Spotify playlist track currently played in radio.
 */
export const usePostPillowSpotifyV2SpotifyTrack_addRadio_id =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<any>, RequestError | Error,{radio_id: string, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<any>, RequestError | Error, {radio_id: string, _extraVariables?: TExtra }>((
             { radio_id,
          
          
          }
          )=>postPillowSpotifyV2SpotifyTrack_addRadio_id(
             radio_id,
          
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Connect Deezer user.
 */
export const usePostPillowUserV2UsersMeDeezer =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<UserResponse>, RequestError | Error,{requestBody: DeezerConnectRequest, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<UserResponse>, RequestError | Error, {requestBody: DeezerConnectRequest, _extraVariables?: TExtra }>((
             { 
          requestBody,
          
          }
          )=>postPillowUserV2UsersMeDeezer(
             
          requestBody,
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Update user's premium.
 */
export const usePostPillowUserV2UsersMePremium =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<User>, RequestError | Error,{requestBody: UserPremiumUpdateRequest, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<User>, RequestError | Error, {requestBody: UserPremiumUpdateRequest, _extraVariables?: TExtra }>((
             { 
          requestBody,
          
          }
          )=>postPillowUserV2UsersMePremium(
             
          requestBody,
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Connect spotify user.
 */
export const usePostPillowUserV2UsersMeSpotify =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<UserResponse>, RequestError | Error,{requestBody: SpotifyConnectRequest, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<UserResponse>, RequestError | Error, {requestBody: SpotifyConnectRequest, _extraVariables?: TExtra }>((
             { 
          requestBody,
          
          }
          )=>postPillowUserV2UsersMeSpotify(
             
          requestBody,
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Password reset.
 */
export const usePostPillowUserV2UsersPasswordReset =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<any>, RequestError | Error,{requestBody: UserPasswordResetRequest, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<any>, RequestError | Error, {requestBody: UserPasswordResetRequest, _extraVariables?: TExtra }>((
             { 
          requestBody,
          
          }
          )=>postPillowUserV2UsersPasswordReset(
             
          requestBody,
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * Register user.
 */
export const usePostPillowUserV2UsersRegister =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<User>, RequestError | Error,{requestBody: UserCreateRequest, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<User>, RequestError | Error, {requestBody: UserCreateRequest, _extraVariables?: TExtra }>((
             { 
          requestBody,
          
          }
          )=>postPillowUserV2UsersRegister(
             
          requestBody,
          
          
            configOverride,
          ),
          options
         )  
          }
        
      
/**
 * set list of presets of the user
 */
export const usePutAccountUsersMePresets =<TExtra> (
           options?:UseMutationOptions<SwaggerResponse<any>, RequestError | Error,{requestBody: RequestBodyPresetArray,queryParams: PutAccountUsersMePresetsQueryParams, _extraVariables?:TExtra}>,configOverride?:AxiosRequestConfig
           ) => {return useMutation<SwaggerResponse<any>, RequestError | Error, {requestBody: RequestBodyPresetArray,queryParams: PutAccountUsersMePresetsQueryParams, _extraVariables?: TExtra }>((
             { 
          requestBody,
          queryParams,
          }
          )=>putAccountUsersMePresets(
             
          requestBody,
          queryParams,
          
            configOverride,
          ),
          options
         )  
          }
        