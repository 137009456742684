import React from "react";
interface IconProps extends React.SVGProps<SVGSVGElement> {
  className?: string;
}
const Logo: React.FC<IconProps> = (props) => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 125 24" fill="none" {...props}>
      <path
        d="M4.783 15.517v8.448H0V2h6.59c3.083 0 5.35.552 6.804 1.62 1.488 1.104 2.197 2.725 2.197 4.966 0 1.276-.355 2.448-1.099 3.448s-1.772 1.794-3.118 2.38C14.776 19.344 16.972 22.552 18 24h-5.315l-5.386-8.448-2.516-.035zm0-3.793h1.56c1.523 0 2.622-.241 3.366-.724.708-.483 1.063-1.276 1.063-2.31 0-1.035-.355-1.793-1.099-2.207-.744-.449-1.878-.655-3.437-.655H4.783v5.896z"
        fill="#fff"
      />
      <path
        d="M30.094 23.68l-1.508-2.353h-.11c-.81.998-1.655 1.71-2.538 2.103-.883.392-2.023.57-3.42.57-1.73 0-3.09-.463-4.047-1.426-.993-.962-1.471-2.317-1.471-4.063 0-1.854.662-3.208 1.986-4.063 1.324-.892 3.347-1.355 6.032-1.462l3.127-.107v-.748c0-1.747-.92-2.638-2.796-2.638-1.434 0-2.685.214-4.597 1.07l-2.06-2.995C20.752 6.535 23.032 6 25.57 6c2.39 0 4.23.499 5.517 1.533C32.375 8.566 33 10.099 33 12.166V23.75h-2.906v-.07zm-2.023-8.056l-1.876.071c-1.434.036-2.464.285-3.163.749-.699.463-1.03 1.14-1.03 2.067 0 1.354.773 1.996 2.354 1.996 1.14 0 2.023-.32 2.722-.962.699-.642 1.03-1.462 1.03-2.531l-.037-1.39zM40.485 24c-2.01 0-3.595-.77-4.758-2.344C34.564 20.082 34 17.948 34 15.184c0-2.8.6-4.968 1.762-6.543 1.163-1.574 2.784-2.344 4.828-2.344 2.15 0 3.806.84 4.934 2.484h.141a17.886 17.886 0 01-.352-3.393V0H50v23.72h-2.925l-1.516-2.204h-.211C44.291 23.16 42.67 24 40.485 24zm1.656-3.674c1.198 0 2.08-.35 2.643-1.049.564-.7.846-1.854.916-3.534v-.49c0-1.819-.282-3.148-.845-3.918-.564-.804-1.48-1.19-2.784-1.19-1.058 0-1.868.456-2.432 1.33-.564.875-.881 2.17-.881 3.849 0 1.679.282 2.938.88 3.778.565.805 1.41 1.224 2.503 1.224zM52 2.3C52 .78 52.833 0 54.5 0S57 .779 57 2.3c0 .744-.208 1.31-.625 1.7-.417.425-1.042.602-1.875.602-1.632 0-2.5-.779-2.5-2.301zM56.826 24h-4.618V6.726h4.618V24zM75 14.982c0 2.816-.71 5.026-2.131 6.63-1.42 1.604-3.383 2.388-5.92 2.388-1.59 0-2.977-.356-4.194-1.105-1.218-.713-2.131-1.782-2.774-3.137C59.338 18.404 59 16.8 59 14.982c0-2.851.71-5.026 2.097-6.63C62.518 6.784 64.48 6 67.017 6c1.59 0 2.977.356 4.194 1.07 1.218.712 2.132 1.781 2.774 3.136.71 1.354 1.015 2.958 1.015 4.776zm-11.4 0c0 1.711.271 3.03.812 3.885.541.891 1.421 1.32 2.605 1.32s2.063-.429 2.57-1.32c.542-.89.779-2.174.779-3.92 0-1.711-.27-2.994-.812-3.85-.541-.855-1.387-1.283-2.605-1.283-1.184 0-2.063.428-2.604 1.283-.474.856-.745 2.174-.745 3.885z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77 24h5V0h-5v24zM87.5 0C85.833 0 85 .779 85 2.3c0 1.523.833 2.302 2.5 2.302.833 0 1.458-.177 1.875-.602.417-.39.625-.956.625-1.7C90 .78 89.167 0 87.5 0zm-2.326 24h4.618V6.726h-4.618V24zM108 24h-4.718V13.678c0-1.268-.213-2.245-.674-2.861-.462-.652-1.136-.942-2.129-.942-1.313 0-2.27.435-2.874 1.34-.603.906-.887 2.39-.887 4.491V24H92V6.326h2.803l1.454 2.245h.248c.533-.869 1.242-1.484 2.165-1.92.922-.434 1.986-.651 3.157-.651 2.022 0 3.548.543 4.577 1.666 1.028 1.123 1.561 2.716 1.561 4.817L108 24zm4.21-2.317c1.473 1.533 3.549 2.317 6.161 2.317 1.272 0 2.343-.071 3.214-.25.904-.213 1.741-.498 2.511-.926v-3.672c-.938.464-1.875.82-2.746 1.034-.87.214-1.808.32-2.779.32-1.272 0-2.243-.391-2.946-1.14-.703-.748-1.105-1.782-1.138-3.136H125v-2.317c0-2.495-.636-4.42-1.908-5.81C121.786 6.713 120.011 6 117.701 6c-2.411 0-4.286.784-5.659 2.388C110.67 9.956 110 12.202 110 15.125c0 2.816.737 5.026 2.21 6.558zm3.315-11.335c.535-.641 1.272-.962 2.209-.962.938 0 1.708.32 2.277.963.569.641.837 1.532.871 2.708h-6.261c.067-1.176.368-2.067.904-2.709z"
        fill="url(#prefix__paint0_linear_4321_10746)"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear_4321_10746"
          x1={101}
          y1={0}
          x2={101}
          y2={24}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2371B9" />
          <stop offset={1} stopColor="#413CC0" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoLogo = React.memo(Logo);
export default MemoLogo;
