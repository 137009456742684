import clsx from "clsx";
import React from "react";
import { ReactElement } from "react";
import MemoLoader from "../icons/Loader";
import styles from "./button.module.scss";

interface ButtonProps {
  message?: string | number | null;
  icon?: ReactElement;
  small?: boolean;
  type?: "button" | "submit" | "reset";
  accent?: boolean;
  compact?: boolean;
  loading?: boolean;
}

export const Button = React.forwardRef<
  HTMLButtonElement,
  React.HTMLProps<HTMLButtonElement> & ButtonProps
>(
  (
    {
      className,
      icon,
      small,
      compact,
      children,
      loading,
      accent,
      ...buttonProps
    },
    ref
  ) => {
    return (
      <button
        {...buttonProps}
        className={clsx(
          styles.button,
          {
            [styles.small]: small,
            [styles.accent]: accent,
            [styles.compact]: compact,
          },
          className
        )}
        ref={ref}
      >
        {loading ? <MemoLoader /> : children}
      </button>
    );
  }
);

export const ButtonText = React.forwardRef<
  HTMLButtonElement,
  React.HTMLProps<HTMLButtonElement> & ButtonProps
>(({ className, icon, small, children, ...buttonProps }, ref) => {
  return (
    <button
      {...buttonProps}
      className={clsx(styles.button_text, { [styles.small]: small }, className)}
      ref={ref}
    >
      {children}
    </button>
  );
});

export const ButtonOutline = React.forwardRef<
  HTMLButtonElement,
  React.HTMLProps<HTMLButtonElement> & ButtonProps
>(({ className, icon, children, small, ...buttonProps }, ref) => {
  return (
    <button
      {...buttonProps}
      className={clsx(
        styles.button,
        styles.button_outline,
        { [styles.small]: small },
        className
      )}
      ref={ref}
    >
      {children}
    </button>
  );
});

export const ButtonDanger = React.forwardRef<
  HTMLButtonElement,
  React.HTMLProps<HTMLButtonElement> & ButtonProps
>(({ className, icon, children, small, ...buttonProps }, ref) => {
  return (
    <button
      {...buttonProps}
      className={clsx(
        styles.button,
        styles.button_danger,
        { [styles.small]: small },
        className
      )}
      ref={ref}
    >
      {children}
    </button>
  );
});

ButtonOutline.displayName = "ButtonOutline";
Button.displayName = "Button";
ButtonDanger.displayName = "ButtonDanger";
