import Script from "next/script";
import { ActiveMedia, playerActions } from "app/store/slices/player";
import { useAppDispatch } from "app/store/store";
import { createContext, useContext, useEffect, useState } from "react";
import { useGetPillowAdvertisingTargetspot } from "services-hooks/hooks";
import { useFeaturesContext } from "./features.context";

export const LOCAL_STORAGE_COUNTRY_KEY = "countryCode";
export const LOCAL_STORAGE_LOCALE_KEY = "locale";

interface PlayerContextValue {
  isPlaying: boolean;
  audioDots: { playerConstructor: null | any };
  isMediaLoading: boolean;
  duration: number | null;
  playerEnabled: boolean;
  setPlayerEnabled: (enabled: boolean) => void;
  fullscreenPlayerInitialyOpen: boolean;
  setFullscreenPlayerInitialyOpen: (enabled: boolean) => void;
  setIsPlaying: (enabled: boolean) => void;
  setIsMediaLoading: (enabled: boolean) => void;
  setDuration: (duration: number | null) => void;
}

const PlayerContext = createContext<PlayerContextValue | null>(null);

export const PlayerContextProvider: React.FC = ({ children }) => {
  const [fullscreenPlayerInitialyOpen, setFullscreenPlayerInitialyOpen] =
    useState(false);
  const [playerEnabled, setPlayerEnabled] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMediaLoading, setIsMediaLoading] = useState(false);
  const [duration, setDuration] = useState<number | null>(null);
  const dispatch = useAppDispatch();
  const { data: config } = useGetPillowAdvertisingTargetspot({
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: 1,
  });

  useEffect(() => {
    dispatch(playerActions.setTargetspoConfig(config?.data || null));
  }, [config?.data]);
  const { audioDotsEnabled, audioDotsPublisherId, audioDotsTagId } =
    useFeaturesContext();
  const [audioDots, setAudioDots] = useState<{
    playerConstructor: any | null;
  }>({ playerConstructor: null });
  return (
    <PlayerContext.Provider
      value={{
        audioDots,
        fullscreenPlayerInitialyOpen,
        setFullscreenPlayerInitialyOpen,
        playerEnabled,
        isPlaying,
        duration,
        setDuration,
        setIsPlaying,
        setIsMediaLoading,
        isMediaLoading,
        setPlayerEnabled,
      }}
    >
      {audioDotsEnabled && (
        <Script
          id="aniplayer"
          src={`https://player.aniview.com/script/6.1/aniview.js?v=1&type=s&pid=${audioDotsPublisherId}`}
          onLoad={() => {
            setAudioDots({ playerConstructor: (window as any).avPlayer });
          }}
        />
      )}
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayerContext = (): PlayerContextValue => {
  const value = useContext(PlayerContext);
  if (value === null)
    throw new Error("Make sure that you use your hook inside of PlayerContext");
  return value;
};
