import { useStickyState } from "app/hooks/sticky-state.hook";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";

export const LOCAL_STORAGE_COUNTRY_KEY = "countryCode";
export const LOCAL_STORAGE_LOCALE_KEY = "locale";

interface GoBackStackItem {
  id: string;
  enabled: boolean;
}

interface NavigationContextValue {
  isOverlayPresent: boolean;
  setIdActive: (id: string, active: boolean) => void;
  removeId: (id: string) => void;
}

const NavigationContext = createContext<NavigationContextValue | null>(null);

export const NavigationContextProvider: React.FC = ({ children }) => {
  const [stack, setStack] = useState<Record<string, boolean>>({});
  const setIdActive = (id: string, active: boolean) => {
    setStack((value) => ({ ...value, [id]: active }));
  };

  const removeId = (id: string) => {
    const newStack = { ...stack };
    delete newStack[id];
    setStack(newStack);
  };
  
  const isOverlayPresent = Object.keys(stack).reduce(
    (isPresent, stackKey) => isPresent || stack[stackKey],
    false
  );
  
  return (
    <NavigationContext.Provider
      value={{
        setIdActive,
        removeId,
        isOverlayPresent,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = (): NavigationContextValue => {
  const value = useContext(NavigationContext);
  if (value === null)
    throw new Error(
      "Make sure that you use your hook inside of NavigationContext"
    );
  return value;
};
