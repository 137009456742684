import { useEffect, useRef } from "react";

export const NAVIGATION_OVERLAY_ATTRIBUTE = "data-navigate-overlay"

export function useArrowKeyNavigationOverlay(active: boolean = true) {
  const parentNode = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (active) {
      parentNode.current?.setAttribute(
        NAVIGATION_OVERLAY_ATTRIBUTE,
        document.querySelectorAll(`[${NAVIGATION_OVERLAY_ATTRIBUTE}]`).length.toString()
      );
    } else {
      parentNode.current?.removeAttribute(NAVIGATION_OVERLAY_ATTRIBUTE);
    }
  }, [active]);

  return parentNode;
}

/**
 * A react hook to enable arrow key navigation on a component.
 * @returns a useRef, which can be applied to a component
 */
export function useArrowKeyNavigation(active: boolean = true) {
  useEffect(() => {
    if (active) {
      const eventHandler = (event: KeyboardEvent) => {
        handleEvents(
          {
            event
          }
        );
      };
      const focusHandler = () => {
        if ((window as any)["Hisense_enableVKB"]) {
          (window as any).Hisense_enableVKB();
        }
      };
      document.addEventListener("keydown", eventHandler);
      const inputElements = document.querySelectorAll("input");
      for (let i = 0; i < inputElements.length; i++) {
        inputElements[i].addEventListener("focus", focusHandler);
      }
      return () => {
        document.removeEventListener("keydown", eventHandler);
        for (let i = 0; i < inputElements.length; i++) {
          inputElements[i].removeEventListener("focus", focusHandler);
        }
      };
    }
  }, [active]);
}

function handleKeyPress({
  event,
  activeElementIndex,
  availableElements,
  availableRows,
  activeRowIndex,
  activeElement,
}: {
  event: KeyboardEvent;
  activeElementIndex: number;
  availableElements: HTMLElement[];
  availableRows: HTMLElement[];
  activeRowIndex: number;
  activeElement: HTMLElement;
}) {
  let nextElement: HTMLElement | null = null;
  if (
    event.key === "ArrowDown" ||
    ((window as any)["VK_DOWN"] && event.keyCode === (window as any)["VK_DOWN"])
  ) {
    const nextRow = availableRows[activeRowIndex + 1];
    if (nextRow) {
      const availableItems = Array.from(
        nextRow.querySelectorAll("[data-navigate-item]")
      ) as HTMLElement[];
      const previousActiveItem = nextRow.querySelector(
        "[data-navigate-last-active]"
      ) as HTMLElement;
      if (previousActiveItem) {
        previousActiveItem.removeAttribute("data-navigate-last-active");
      }
      const nextRowSyncPositionId = nextRow.getAttribute(
        "data-sync-scroll-position"
      );
      const activeRowSyncPositionId = availableRows[
        activeRowIndex
      ]?.getAttribute("data-sync-scroll-position");
      if (
        nextRowSyncPositionId &&
        activeRowSyncPositionId &&
        nextRowSyncPositionId === activeRowSyncPositionId &&
        activeElementIndex > 0 &&
        activeElementIndex < availableItems.length
      ) {
        nextElement = availableItems[activeElementIndex];
      } else {
        if (previousActiveItem) {
          nextElement = previousActiveItem;
        } else if (availableItems[0]) {
          nextElement = availableItems[0];
        }
      }

      if (nextElement) {
        nextElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }

  if (
    event.key === "ArrowUp" ||
    ((window as any)["VK_UP"] && event.keyCode === (window as any)["VK_UP"])
  ) {
    const previousRow = availableRows[activeRowIndex - 1];

    if (previousRow) {
      const availableItems = Array.from(
        previousRow.querySelectorAll("[data-navigate-item]")
      ) as HTMLElement[];
      const previousActiveItem = previousRow.querySelector(
        "[data-navigate-last-active]"
      ) as HTMLElement;

      if (previousActiveItem) {
        previousActiveItem.removeAttribute("data-navigate-last-active");
      }
      const previousRowSyncPositionId = previousRow.getAttribute(
        "data-sync-scroll-position"
      );
      const activeRowSyncPositionId = availableRows[
        activeRowIndex
      ].getAttribute("data-sync-scroll-position");
      if (
        previousRowSyncPositionId &&
        activeRowSyncPositionId &&
        previousRowSyncPositionId === activeRowSyncPositionId &&
        activeElementIndex > 0 &&
        activeElementIndex < availableItems.length
      ) {
        nextElement = availableItems[activeElementIndex];
      } else {
        if (previousActiveItem) {
          nextElement = previousActiveItem;
        } else if (availableItems[0]) {
          nextElement = availableItems[0];
        }
      }
      if (nextElement) {
        nextElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }
  const activeRow = availableRows[activeRowIndex];
  if (
    event.key === "ArrowRight" ||
    ((window as any)["VK_RIGHT"] &&
      event.keyCode === (window as any)["VK_RIGHT"])
  ) {
    if (activeElementIndex < availableElements.length - 1) {
      const width = activeElement.clientWidth;
      if (activeRow.dataset.navigateScrollable) {
        if (
          activeRow.dataset.navigateScrollableParent &&
          activeRow.parentNode
        ) {
          (activeRow.parentNode as HTMLElement).scrollTo({
            left: width * (activeElementIndex + 1),
            behavior: "smooth",
          });
        } else {
          activeRow.scrollTo({
            left: width * (activeElementIndex + 1),
            behavior: "smooth",
          });
        }
      }
      nextElement = availableElements[activeElementIndex + 1];
      activeElement.removeAttribute("data-navigate-last-active");
    }
  }

  if (
    event.key === "ArrowLeft" ||
    ((window as any)["VK_LEFT"] && event.keyCode === (window as any)["VK_LEFT"])
  ) {
    if (activeElementIndex > 0) {
      const width = availableElements[activeElementIndex - 1].clientWidth;
      if (activeRow.dataset.navigateScrollable) {
        if (
          activeRow.dataset.navigateScrollableParent &&
          activeRow.parentNode
        ) {
          (activeRow.parentNode as HTMLElement).scrollTo({
            left: (activeElementIndex - 1) * width,
            behavior: "smooth",
          });
        } else {
          activeRow.scrollTo({
            left: (activeElementIndex - 1) * width,
            behavior: "smooth",
          });
        }
      }
      nextElement = availableElements[activeElementIndex - 1];
      activeElement.removeAttribute("data-navigate-last-active");
    }
  }

  if (
    event.key === "Enter" ||
    ((window as any)["VK_ENTER"] &&
      event.keyCode === (window as any)["VK_ENTER"])
  ) {
    if (activeElementIndex === -1) return;
    const path = event?.composedPath() as HTMLElement[];
    console.log("Enter pressed, composedPath: ", path);
    console.log("Active element:", document.activeElement, path[0].nodeName);
    if (path[0].nodeName !== "INPUT" && path[0].nodeName !== "TEXTAREA") {
      activeElement.click();
      event.preventDefault();
    }
  }

  if (nextElement) {
    nextElement.setAttribute("data-navigate-last-active", "true");
    nextElement.focus({ preventScroll: true });
    event.preventDefault();
  }
}

/**
 * Implement arrow key navigation for the given parentNode
 * @param {object}  options
 * @param {Event}   options.e          Keydown event
 * @param {DOMNode} options.parentNode The parent node to operate on. Arrow keys won't navigate outside of this node
 * @param {String}  options.selectors  Selectors for elements we want to be able to key through
 */
export default function handleEvents({ event }: { event: KeyboardEvent }) {
  console.log("KEYBOARD_EVENT", event.key, event.keyCode);
  detectDidomiPopup();
  const overlays = Array.from(
    document.querySelectorAll(`[${NAVIGATION_OVERLAY_ATTRIBUTE}]`)
  );
  const parentNode =
    overlays.length > 0 ? overlays[overlays.length - 1] : document.body;

  const key = event.key;
  if (
    !["ArrowUp", "ArrowDown", "Enter", "ArrowRight", "ArrowLeft"].includes(key)
  ) {
    return;
  }
  const activeElement = document.activeElement as HTMLElement;
  // If we're not inside the container, don't do anything
  // if (!parentNode.contains(activeElement)) return;
  const availableRows = Array.from(
    parentNode.querySelectorAll('[data-navigate-row="true"]')
  ) as HTMLElement[];
  let activeRowIndex = 0;
  if (activeElement) {
    const closestRow = activeElement.closest(
      '[data-navigate-row="true"]'
    ) as HTMLElement;
    if (closestRow) {
      const availableRowsIndex = availableRows.indexOf(closestRow);
      if (availableRowsIndex >= 0) {
        activeRowIndex = availableRowsIndex;
      }
    }
  }

  const activeRow = availableRows[activeRowIndex] || null;
  // Get the list of elements we're allowed to scroll through
  const availableItems = activeRow
    ? (Array.from(
      activeRow.querySelectorAll('[data-navigate-item="true"]')
    ) as HTMLElement[])
    : ([] as HTMLElement[]);

  const activeElementIndex = Array.from(availableItems).findIndex(
    (availableElement) => availableElement === activeElement
  );
  if (activeElementIndex === -1 && availableItems[0]) {
    availableItems[0].focus();
  }

  handleKeyPress({
    event,
    activeElementIndex,
    availableElements: availableItems,
    availableRows: availableRows,
    activeRowIndex,
    activeElement,
  });
}

const detectDidomiPopup = () => {
  const didomiOverlay = document.querySelector("#didomi-popup");
  const didomiDetailsOverlay = document.querySelector("#didomi-consent-popup");

  if (didomiOverlay) {
    didomiOverlay.setAttribute(NAVIGATION_OVERLAY_ATTRIBUTE, "true");
    didomiOverlay
      .querySelector("#buttons")!
      .setAttribute("data-navigate-row", "true");
    didomiOverlay
      .querySelector("#didomi-notice-learn-more-button")!
      .setAttribute("data-navigate-item", "true");
    didomiOverlay
      .querySelector("#didomi-notice-agree-button")!
      .setAttribute("data-navigate-item", "true");

    if (!didomiOverlay.querySelector(".tmp-link-wrapper")) {
      const newDiv = document.createElement("DIV");
      newDiv.classList.add("tmp-link-wrapper");
      newDiv.setAttribute("data-navigate-row", "true");

      didomiOverlay.querySelector(".didomi-popup-view")!.insertBefore(newDiv, didomiOverlay.querySelector(".didomi-continue-without-agreeing"));
      newDiv.appendChild(
        didomiOverlay.querySelector(".didomi-continue-without-agreeing")!
      );
    }
    didomiOverlay
      .querySelector(".didomi-continue-without-agreeing")!
      .setAttribute("data-navigate-item", "true");
  }
  if (didomiDetailsOverlay) {
    if (didomiOverlay) {
      didomiOverlay.removeAttribute(NAVIGATION_OVERLAY_ATTRIBUTE);
      didomiOverlay
        .querySelector("#buttons")!
        .removeAttribute("data-navigate-row");
      didomiOverlay
        .querySelector("#didomi-notice-learn-more-button")!
        .removeAttribute("data-navigate-item");
      didomiOverlay
        .querySelector("#didomi-notice-agree-button")!
        .removeAttribute("data-navigate-item");

      didomiOverlay
        .querySelector(".didomi-continue-without-agreeing")!
        .removeAttribute("data-navigate-item");
    }

    didomiDetailsOverlay.setAttribute(NAVIGATION_OVERLAY_ATTRIBUTE, "true");
    didomiDetailsOverlay
      .querySelector(".didomi-consent-popup-actions")!
      .setAttribute("data-navigate-row", "true");

    didomiDetailsOverlay
      .querySelectorAll(".didomi-components-button")
      .forEach((item) => item.setAttribute("data-navigate-item", "true"));
  }
};
