import { useEffect } from "react";

export const useVirtualPlayPauseEvent = (onPlay: VoidFunction, onPause: VoidFunction, enabled: boolean) => {
  useEffect(() => {
    if (enabled) {
      const eventHandler = (event: KeyboardEvent) => {
        if (
          ((window as any)["VK_PLAY"] &&
            event.keyCode === (window as any)["VK_PLAY"])
        ) {
          onPlay();
        }
        if (
          ((window as any)["VK_PAUSE"] &&
            event.keyCode === (window as any)["VK_PAUSE"])
        ) {
          onPause();
        }
      };
      document.addEventListener("keydown", eventHandler);
      return () => document.removeEventListener("keydown", eventHandler);
    }
  }, [onPlay, enabled]);
};
